'use client'

import { useActionState } from 'react'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { loginUserAction } from '@/data/actions/auth-actions'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { StrapiErrors } from '@/components/custom/strapi-errors'
import { SubmitButton } from '@/components/custom/submit-button'
import { ZodErrors } from '@/components/custom/zod-errors'

const INITIAL_STATE = {
  zodErrors: null,
  strapiErrors: null,
  data: null,
  message: null,
}

export function SigninForm() {
  const searchParams = useSearchParams()
  const domain = searchParams.get('domain')
  const redirect_url = searchParams.get('redirect_url')

  const [formState, formAction] = useActionState(loginUserAction, INITIAL_STATE)
  return (
    <div className="w-full max-w-lg">
      <form action={formAction}>
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-xl lg:text-3xl font-medium text-center">Login</CardTitle>
            {/* <CardDescription>Enter your details to sign in to your account</CardDescription> */}
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Username or Email</Label>
              <Input
                id="identifier"
                name="identifier"
                type="text"
                placeholder="username or email"
                className="border"
              />
              <ZodErrors error={formState?.zodErrors?.identifier} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="password"
                className="border"
              />
              <ZodErrors error={formState.zodErrors?.password} />
            </div>
            <Input type="hidden" name="domain" value={domain || ''} />
            <Input type="hidden" name="redirect_url" value={redirect_url || ''} />
          </CardContent>
          <CardFooter className="flex flex-col">
            <SubmitButton
              className="w-full bg-background hover:bg-orange-700"
              text="Login"
              loadingText="Loading"
            />
            <StrapiErrors error={formState?.strapiErrors} />
          </CardFooter>
        </Card>
        <div className="mt-4 text-center text-sm">
          Don't have an account?
          <Link className="underline ml-2" href="/register">
            Register
          </Link>
        </div>
      </form>
    </div>
  )
}
